import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getAllSettingsQql } from "../../../services/query.service";
import styled from "@emotion/styled";
import { converDateTimeToPst } from "../../../services/util.service";
import ApiAction from "../../Common/ApiAction";
import { useMutation } from "@apollo/client";
import { updateCacheSettingGql } from "../../../services/mutation.service";

const StyledTableCell = styled(TableCell)<any>(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
    fontSize: 16,
  },
}));
export default function SettingsCache() {
  const [cacheName, setCacheName] = useState("");

  const [allSettings, setAllSettings] = useState(Array());
  const [getAllSettings] = useLazyQuery(getAllSettingsQql);
  useEffect(() => {
    getAllSettings({
      onCompleted: (data) => {
        setAllSettings(data?.getAllSettings?.payload?.allSettings || []);
      },
    });
  }, []);
  const [update, { loading: isLoading }] = useMutation(updateCacheSettingGql, {
    variables: {
      input: {
        cacheName: cacheName,
      },
    },
    onCompleted: (data: any) => {
      const newAllSettings = allSettings.map(obj =>
        (obj.key === cacheName || cacheName === "ALLSETTINGS") ? { ...obj, lastRun: data?.updateCacheSetting?.payload?.lastRun } : obj
      );
      setAllSettings(newAllSettings);
      setCacheName("");
    },
    onError: (err: any) => {
      setCacheName("");
    },
  });
  useEffect(() => {
    if (cacheName) update();
  }, [cacheName]);
  return (
    <>
      <Typography variant="h4" component="h1" sx={{ fontWeight: "600", mb: 2 }}>
        Cache Purge
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Cache Purge">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell width="25%">Last Run</StyledTableCell>
              <StyledTableCell width="5%" align="center">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allSettings?.map((setting, index) => {
              return (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={`setting${index}`}>
                  <TableCell>
                    {setting.key}
                  </TableCell>
                  <TableCell>{setting.lastRun ? converDateTimeToPst(+setting.lastRun) : '-'}</TableCell>
                  <TableCell align="center">
                    <ApiAction key={`btnSetting${index}`}
                      onClick={(e: any) => {
                        if (!!isLoading) {
                          return;
                        }
                        setCacheName(setting.key);
                      }}
                      loading={!!isLoading}
                      disabled={!!isLoading}
                    ></ApiAction>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
