import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export default function Sidebar() {
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(location.pathname === "/dashboard" ? 0 : 1);
  const navigate = useNavigate();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    navigate(index === 0 ? "/dashboard" : '/settings');
  };
  return (
    <List>
      {["Cache Purge", "Redis Backup"].map((text, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)}>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
